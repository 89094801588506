@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply bg-[#1A1919] text-white leading-5;
    font-family: CenturyExpanded;
    font-size: 80%;
}

@media screen and (min-width: 375px) {
    html {
        font-size: 85%;
    }
}

/* @link https://utopia.fyi/type/calculator?c=768,14,1.2,2560,24,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

@media screen and (min-width: 768px) {
    html {
        font-size: clamp(0.88rem, calc(0.5rem + 0.78vw), 1.75rem);
    }
}
/*
@media screen and (min-width: 768px) {
    html {
        font-size: 100%;
    }
}

@media screen and (min-width: 1024px) {
    html {
        font-size: 150%;
    }
}
*/

.stroke {
    -webkit-text-stroke-width: 0.75px;
    -webkit-text-stroke-color: white;
}

@media screen and (min-width: 768px) {
    .stroke {
        -webkit-text-stroke-width: 0px;
        text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
            1px 1px 0 white;
    }
}

::selection {
    background: #1a1919;
    color: white;
    text-shadow: none;
}

div:focus {
    @apply outline-none !important;
}

.artwork-image {
    @apply max-w-[100%] max-h-[56vh] w-auto h-auto;
}

.paragraphs-text p:not(:first-child) {
    @apply indent-16;
}

.mobile-height {
    min-height: calc(var(--vh, 1vh) * 100);
}
/*
.paragraphs-text p:not(:first-child)::first-line {
    text-align: left;
    width: 50%;
}

.paragraphs-text p:not(:first-child)::before {
    background: none;
    display: inline-block;
    width: 43%;
    height: 0.5rem;
    content: "";
}

*/
